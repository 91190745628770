import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df212a54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact" }
const _hoisted_2 = { class: "contact__box" }
const _hoisted_3 = { class: "contact__header" }
const _hoisted_4 = {
  class: "contact__actions",
  type: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.t("CONTACT.HEADER")), 1 /* TEXT */),
        _createElementVNode("p", null, _toDisplayString(_ctx.t("CONTACT.SUB_HEADER")), 1 /* TEXT */)
      ]),
      _createVNode(_component_a_form, {
        class: "contact__form",
        name: "contact",
        model: _ctx.model,
        rules: _ctx.rules,
        ref: "formTemplate",
        autocomplete: "off",
        onSubmit: _ctx.send
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            class: "contact__email",
            name: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                placeholder: _ctx.t('CONTACT.EMAIL'),
                value: _ctx.model.email,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.email) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_form_item, {
            class: "contact__name",
            name: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                placeholder: _ctx.t('CONTACT.FULLNAME'),
                value: _ctx.model.name,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_form_item, {
            class: "contact__subject",
            name: "subject"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                placeholder: _ctx.t('CONTACT.SUBJECT'),
                value: _ctx.model.subject,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.subject) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_form_item, {
            class: "contact__message",
            name: "message"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                placeholder: _ctx.t('CONTACT.MESSAGE_PLACEHOLDER'),
                value: _ctx.model.message,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.message) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_vue_recaptcha, {
            siteKey: _ctx.recaptchaSiteKey,
            size: "normal",
            theme: "light",
            tabindex: 0,
            onVerify: _cache[4] || (_cache[4] = ($event: any) => (_ctx.recaptchaSuccess = true))
          }, null, 8 /* PROPS */, ["siteKey"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_col, {
              lg: 12,
              md: 12,
              sm: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  class: "contact__button contact__submit",
                  type: "primary",
                  "html-type": "submit",
                  loading: _ctx.loading,
                  disabled: !_ctx.valid || !_ctx.recaptchaSuccess
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("CONTACT.SEND")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["loading", "disabled"]),
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      class: "contact__button",
                      type: "ghost"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("CONTACT.CANCEL")), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules", "onSubmit"])
    ])
  ]))
}