
import {
  defineComponent,
  Ref,
  ref,
  inject,
  reactive,
  watch,
  computed,
} from "vue";
import { AxiosStatic } from "axios";
import vueRecaptcha from "vue3-recaptcha2";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { Message } from "../../types";
import { RuleObject } from "ant-design-vue/es/form/interface";
import { Form } from "ant-design-vue";
import { useStore } from "../store";

const useForm = Form.useForm;

interface Model {
  email: string;
  name: string;
  subject: string;
  message: string;
}

export const ContactComponent = defineComponent({
  components: { vueRecaptcha },
  setup() {
    const http = inject("http") as AxiosStatic;
    const store = useStore();

    const recaptchaSiteKey = computed(
      () => store.state.runtimeConfig.recapthaSiteKey
    );
    const valid: Ref<boolean> = ref(false);
    const loading: Ref<boolean> = ref(false);
    const recaptchaSuccess: Ref<boolean> = ref(false);
    const { t } = useI18n();

    const model: Model = reactive({
      email: "",
      name: "",
      subject: "",
      message: "",
    });

    const rules: Record<string, Array<RuleObject>> = reactive({
      email: [
        {
          required: true,
          pattern: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
          message: t("CONTACT.EMAIL_INVALID"),
        },
      ],
      name: [
        {
          required: true,
          message: t("CONTACT.FIELD_REQUIRED"),
        },
      ],
      subject: [
        {
          required: true,
          message: t("CONTACT.FIELD_REQUIRED"),
        },
      ],
      message: [
        {
          required: true,
          message: t("CONTACT.FIELD_REQUIRED"),
        },
      ],
    });

    const form = useForm(model, rules);
    const formTemplate = ref();

    watch(
      model,
      () => {
        form
          .validate()
          .then(() => {
            valid.value = true;
          })
          .catch(() => {
            valid.value = false;
          });
      },
      { deep: true }
    );

    const send = async () => {
      loading.value = true;
      try {
        const reqBody: Message = {
          name: model.name,
          email: model.email,
          message: `Tytuł: ${model.subject} Wiadomość: ${model.message}`,
        };
        await http.post("patient-portal/api/contact/message", reqBody);

        formTemplate.value.resetFields();
        notification.open({
          message: t("CONTACT.SEND_SUCCESS"),
          class: "success",
        });
      } catch {
        notification.open({
          message: t("ERROR.4887"),
          class: "error",
        });
      }
      loading.value = false;
    };

    return {
      recaptchaSiteKey,
      recaptchaSuccess,
      valid,
      loading,
      form,
      formTemplate,
      send,
      model,
      rules,
      t,
    };
  },
});

export default ContactComponent;
